import * as React from "react"
import { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { AiOutlineCloseCircle } from "@react-icons/all-files/ai/AiOutlineCloseCircle"
export default function IndexPage() {
  const [open, setOpen] = useState(false)
  return (
    <div className="min-h-full pb-10 text-md bg-cover font-openSans">
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700&family=Source+Sans+Pro&display=swap"
        rel="stylesheet"
      ></link>
      <div className="relative bg-indigo-800">
        <img
          className="w-full h-full"
          src="/static-images/hero-image.png"
          alt=""
        />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center mt-10">
          <p className="text-2xl sm:text-3xl font-semibold	mb-3">
            CAPEX Metaverse Party
          </p>
          <p className="text-4xl sm:text-5xl font-bold mb-3">
            December 16, 2021,
          </p>
          <p className="text-xl sm:text-4xl text-[#434343]">
            from 4 PM EET Time
          </p>
        </div>
        <div className="max-w-4xl mx-auto mt-10">
          <p className="bg-[#f8f8f8]  font-light py-[23px] px-[30px] rounded-lg mb-[40px] shadow-sm">
            2021 has been a memorable year, yet we all pushed through, overcame
            our challenges, reached new highs, and showed the world 🌎 and
            ourselves that our success directly results from our hard work and
            dedication <span className="font-normal">💸</span>
          </p>
          <p className="bg-[#f8f8f8]  font-light py-[23px] px-[30px] rounded-lg mb-[40px] shadow-sm">
            This calls for a toast, and what better way to have it than in our
            own{" "}
            <span className="text-[#ff4848] font-bold">
              CAPEX Metaverse Christmas Party!
            </span>{" "}
            <span className="font-normal">🥳</span>
          </p>
          <p className="bg-[#f8f8f8]  font-light py-[23px] px-[30px] rounded-lg mb-[80px]  shadow-sm">
            So, jumping straight to the point, this{" "}
            <span className="font-bold">Thursday, December 16, </span>
            you’re all invited to our unique event.
          </p>
          <div className="bg-[#f8f8f8] pt-[23px] px-[30px]  font-semibold rounded-lg mb-[40px] grid grid-cols-1 md:grid-cols-2  shadow-sm">
            <div>
              <p className="mb-5 font-bold uppercase">Here is the schedule:</p>
              <div className="text-[#737373]">
                <p className="mb-2">
                  <span className="text-[#ff4848]">16:00</span> Welcoming guests
                </p>
                <p className="mb-2">
                  <span className="text-[#ff4848]">18:15</span> CAPEX - Year in
                  Review
                </p>
                <p className="mb-2">
                  <span className="text-[#ff4848]">19:00</span> Music (The
                  Humans)
                </p>
                <p className="mb-2">
                  <span className="text-[#ff4848]">19:50</span> Awards Festivity{" "}
                </p>
                <p className="mb-2">
                  <span className="text-[#ff4848]">20:30</span> Music (The
                  Humans)
                </p>
                <p className="mb-2">
                  <span className="text-[#ff4848]">22:00</span> DJ & Closing of
                  the party
                </p>
              </div>
            </div>
            <div>
              <img
                className="w-full md:-mt-12 mt-5"
                src="/static-images/bg-guy.png"
                alt=""
              />
            </div>
          </div>
          <div className="mb-16">
            <p className="text-4xl sm:text-4xl text-left font-bold leading-[1] mb-8">
              What you need to do to attend the{" "}
              <span className="text-[#ff4848]">CAPEX.com</span>{" "}
              <span className="text-[#0995dd]">Metaverse PHYSICAL Party</span>
              <span className="text-4xl sm:text-4xl font-normal">😷</span>
            </p>
          </div>
          <div className="bg-[#f8f8f8] py-[23px] px-[30px]  font-semibold rounded-lg mb-[40px]">
            <div className="text-center">
              <p className="font-bold uppercase text-black">WHERE</p>
              <p className="mb-5 text-[#737373]">
                Titulescu Hall of FACE CLUB (3-5, Presei Libere Square),
                Bucharest
              </p>
              <p className="font-bold uppercase text-black">WHEN</p>
              <p className="mb-5 text-[#737373]">
                4 PM EET Time <span className="font-normal">🕓</span>
              </p>
              <p className="font-bold uppercase text-black">WHO</p>
              <p className="mb-5 text-[#737373]">
                Only vaccinated or PCR-tested guests can enter the party{" "}
                <span className="font-normal">😷</span> (all COVID-19 health
                protocols are respected and checked upon venue entry)
              </p>
              <p className="font-bold uppercase text-black">HOW</p>
              <p className="text-[#737373]">
                The club will accommodate cocktail tables for all guests and let
                you freely interact with each other and meet on the dance floor
                <span className="font-normal">💃🏻🕺🏽</span>
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-10 py-6 bg-[#ff4848] text-base text-white hover:bg-red-400 sm:text-lg font-bold"
              onClick={() => setOpen(true)}
            >
              Register to PHYSICAL party
            </button>
          </div>
          <div></div>
          <div className="mt-16">
            <p className="text-4xl sm:text-4xl text-left font-bold leading-[1] mb-8">
              What you need to do to attend the{" "}
              <span className="text-[#ff4848]">CAPEX.com</span>{" "}
              <span className="text-[#0995dd]">Metaverse VIRTUAL Party</span>
              <span className="text-4xl sm:text-4xl font-normal">😷</span>
            </p>
          </div>
          <div>
            <img
              className="w-full  mt-16"
              src="/static-images/bg-1.png"
              alt=""
            />
          </div>
          <p className="text-[#737373] mb-16 text-sm">* Virtual Party</p>
          <div className="font-bold flex justify-center">
            <div className="max-w-xl">
              <p className="text-[#ff4848]">Step 1:</p>
              <p className="text-black mb-6">
                Register until 4 PM EET Time, December 16
              </p>
              <p className="text-[#ff4848]">Step 2:</p>
              <p className="text-black mb-6">
                After 4 PM EET Time, by accessing the same link {' '}
                (<a className="text-[#0995dd]" href="http://metaverse.capex.com">
                   http://metaverse.capex.com
                </a>){" "}
                you'll get redirected to the CAPEX Metaverse Virtual Space where
                you need to confirm your email.
              </p>
              <p className="text-[#ff4848]">Step 3:</p>
              <p className="text-black mb-6">
                Make sure you use a laptop{" "}
                <span className="font-normal">💻</span> or a desktop computer{" "}
                <span className="font-normal">🖥️</span> with Google Chrome
              </p>
              <p className="text-[#ff4848]">Step 4:</p>
              <p className="text-black mb-6">
                Once logged into the platform, pick an avatar picture, check
                that your microphone <span className="font-normal">🎤</span>{" "}
                speakers and camera <span className="font-normal">📷</span> are
                selected correctly
              </p>
              <p className="text-[#ff4848]">Step 5:</p>
              <p className="text-black mb-6">
                Follow the instruction presented when you enter the virtual
                space
              </p>
              <p className="text-[#ff4848]">Step 6:</p>
              <p className="text-black mb-6">
                Join a meeting point or a seat at a table to chat with the other
                guests
              </p>
              <p className="text-[#ff4848]">Step 7:</p>
              <p className="text-black mb-6">
                Don’t miss the live event from the big screen and enjoy the
                party
              </p>
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-10 py-6  bg-[#ff4848] text-base text-white hover:bg-red-400 sm:text-lg font-bold"
              onClick={() => setOpen(true)}
            >
              Register to VIRTUAL party
            </button>
          </div>
          <div>
            <img
              className="w-full  mt-16"
              src="/static-images/bg-2.png"
              alt=""
            />
          </div>
          <p className="text-[#737373] mb-16 text-sm">* Physical Party</p>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <iframe
                  title="registration form"
                  width="100%"
                  height="100%"
                  src="https://forms.office.com/Pages/ResponsePage.aspx?id=toxCoNT-80-xLw3Bbjzf6CsYvgv-kllDgmkd9qxNVTFUOTRCUFBFNDI2RE1XMkIyREw5RzZKVlowUC4u&embed=true"
                  frameborder="0"
                  marginwidth="0"
                  marginheight="0"
                  style={{
                    border: "none",
                    maxWidth: "100%",
                    maxHeight: "100vh",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                  allowfullscreen
                  webkitallowfullscreen
                  mozallowfullscreen
                  msallowfullscreen
                ></iframe>
                <AiOutlineCloseCircle
                  onClick={() => setOpen(false)}
                  className="absolute top-[10px] right-[30px] cursor-pointer text-[50px] text-white z-20"
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
